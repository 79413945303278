import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Inject } from '@angular/core';
import { Globals } from '../common/globals';
import * as pbi from 'powerbi-client';
import { Honeycomb } from 'src/app/shared/honeycomb-api/honeycomb-api';

@Component({
  selector: 'hc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  @ViewChild('pbiview', {static: false}) pbiView: ElementRef;
  @ViewChild('imageview', {static: false}) imageview: ElementRef;
  private aad: Honeycomb.Tenant.Reports.IService.Model.PBI.AAD;
  private embed: pbi.Embed;
  private powerbi: pbi.service.Service;

  public showPBIReport = false;

  constructor(
    private globals: Globals,
    @Inject('PBIReportController') private reportController: Honeycomb.Tenant.Reports.IService.PBIController,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    let homePBIReport = this.globals.getHomePBIReportName();
    if (homePBIReport) {
      this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      this.reportController.GetToken().subscribe( r => {
        this.aad = r;
        this.load(homePBIReport);
      });
    }
  }

  private load(reportName: string) {
    this.reportController.GetReport(reportName).subscribe(report => {
      this.embed = this.powerbi.embed(this.pbiView.nativeElement, this.getPBIConfig(report));
      this.embed.reload();
      this.showPBIReport = true;
      this.cd.detectChanges();
    }, err => {
      console.log(err);
    });
  }

  private getPBIConfig(report: Honeycomb.Tenant.Reports.IService.Model.PBI.PBIReport) {
    return <pbi.IEmbedConfiguration>{
        type: 'report',
        id: report.reportID,
        viewMode: pbi.models.ViewMode.View,
        tokenType: pbi.models.TokenType.Aad,
        permissions: pbi.models.Permissions.All,
        pageView: 'actualSize',
        accessToken: this.aad.access_token,
        embedUrl: report.report.embedUrl + '&language=' + this.globals.getLanguage(),
        settings: {
            navContentPaneEnabled: true,
            layoutType: pbi.models.LayoutType.Master
        },
        pageName: 'DefaultPage'
    };
}

  public navTo(path: string) {
    this.globals.navigateClearTo(path);
  }
}
